<template>
  <div id="finance">
    <div class="columns">
      <div class="column">
        <p class="title has-text-grey-light is-6">Outstanding finance</p>
      </div>
      <div class="column is-narrow">
        <p class="title is-6">
          <span
            :class="[finance ? 'has-text-danger' : 'has-text-success']"
            v-if="finance"
            >{{ items[0].agreementType || 'Outstanding finance' }}</span
          >
          <span
            :class="[finance ? 'has-text-danger' : 'has-text-success']"
            v-else
            >Nothing reported</span
          >
        </p>
      </div>
    </div>
    <div v-if="finance">
      <div v-for="(item, index) in items" :key="index">
        <p class="title has-text-grey-light is-6">
          {{ `Finance agreement ${index + 1}` }}
        </p>
        <div class="field">
          <p class="has-text-grey">Finance company</p>
          <p>{{ item.financeCompany }}</p>
        </div>
        <div class="field">
          <p class="has-text-grey">Finance contact number</p>
          <p>{{ item.contactNumber }}</p>
        </div>
        <div class="field">
          <p class="has-text-grey">Agreement date</p>
          <p>{{ formatDate(item.agreementDate) }}</p>
        </div>
        <div class="field">
          <p class="has-text-grey">Agreement reference</p>
          <p>{{ item.agreementNumber }}</p>
        </div>
        <div class="field">
          <p class="has-text-grey">Agreement term</p>
          <p>{{ item.agreementTerm }}</p>
        </div>
        <div class="field">
          <p class="has-text-grey">Agreement type</p>
          <p>{{ item.agreementType }}</p>
        </div>
        <div class="field">
          <p class="has-text-grey">Description of vehicle on agreement</p>
          <p>{{ item.vehicleDescription }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'AutoCheckFinance',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    finance() {
      return this.data.financeDataRecordCount
    },
    items() {
      return this.data.financeDataRecords
    }
  },
  methods: {
    formatDate
  }
}
</script>
